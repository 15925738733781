import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import format from 'date-fns/format';
import './whiteList.css';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getSubLocations } from '../../modules/location/selector';
import { saveWhiteList } from '../../modules/white-list/operations';
import { WhiteListRow } from '../../modules/white-list/models';
import { getSelectedEntry } from '../../modules/white-list/selectors';
import plLocale from 'date-fns/locale/pl';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

export interface DialogProps {
  open: boolean;
  headLocation: string;
  onClose: (saved: boolean) => void;
}

const allDays = ['poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota', 'niedziela'];
const allDaysEn = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const WhiteListDialog = ({ open, headLocation, onClose }: DialogProps) => {
  const dispatch = useAppDispatch();
  const selectedEntry = useAppSelector((state) => getSelectedEntry(state.whiteList));
  const vehicleTypes = useAppSelector((state) => state.whiteList.vehicleTypes);
  const locations = useAppSelector((state) => getSubLocations(state.location, headLocation));

  const [vehiclePlates, setVehiclePlates] = useState('');
  const [description, setDescription] = useState('');
  const [vehiclePlatesError, setVehiclePlatesError] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleTypeError, setVehicleTypeError] = useState('');
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateFromError, setDateFromError] = useState('');
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [dateToError, setDateToError] = useState('');

  const [timeFrom, setTimeFrom] = useState<Date | null>(null);
  const [timeFromError, setTimeFromError] = useState('');
  const [timeTo, setTimeTo] = useState<Date | null>(null);
  const [timeToError, setTimeToError] = useState('');
  const [location, setLocation] = useState<string>('');
  const [locationError, setLocationError] = useState<string>('');

  const [creator, setCreator] = useState<string>('');
  const [creationDate, setCreationDate] = useState<string>('');
  const [lastModifier, setLastModifier] = useState<string>('');
  const [lastModificationDate, setLastModificationDate] = useState<string>('');

  const [isDateRange, setIsTimePeriod] = useState(false);
  const [allDay, setAllDay] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);

  const [days, setDays] = useState(new Array(allDays.length).fill(false));
  const [allSelected, setAllSelected] = useState(false);
  const [daysError, setDaysError] = useState('');

  useEffect(() => {
    if (selectedEntry) {
      setIsEditMode(true);
      setVehiclePlates(selectedEntry.vehiclePlates);
      setVehicleType(selectedEntry.vehicleTypeUuid);
      setDateFrom(new Date(selectedEntry.fromDate));
      setDateTo(new Date(selectedEntry.toDate));
      setTimeFrom(new Date(selectedEntry.fromHour));
      setTimeTo(new Date(selectedEntry.toHour));
      setLocation(selectedEntry.locationUuid);
      setSelectedDays(selectedEntry.daysOfWeek);
      setDescription(selectedEntry.description);
      setCreator(selectedEntry.creator);
      setCreationDate(selectedEntry.creationDate);
      setLastModifier(selectedEntry.lastModifier);
      setLastModificationDate(selectedEntry.lastModificationDate);
    }
  }, [selectedEntry]);

  const handleClose = () => {
    clearForm();
    onClose(false);
  };

  const validateForm = (): boolean => {
    setSaveClicked(true);

    if (vehiclePlates.length < 3) {
      setVehiclePlatesError('Wprowadzony tekst jest za krótki');
      return false;
    } else {
      setVehiclePlatesError('');
    }
    if (vehicleType === '') {
      setVehicleTypeError('Wybierz');
      return false;
    } else {
      setVehicleTypeError('');
    }
    setDateFromError('');
    setDateToError('');
    if (isDateRange) {
      if (!dateFrom) {
        setDateFromError('Wprowadź datę');
        return false;
      }
      if (!dateTo) {
        setDateToError('Wprowadź datę');
        return false;
      }
    }
    if (days.find((x) => x === true) === undefined) {
      setDaysError('Wybierz dni');
      return false;
    } else {
      setDaysError('');
    }

    setTimeFromError('');
    setTimeToError('');
    if (!allDay) {
      if (!timeFrom) {
        setTimeFromError('Wprowadź godzinę');
        return false;
      }
      if (!timeTo) {
        setTimeToError('Wprowadź godzinę');
        return false;
      }
    }

    if (location === '') {
      setLocationError('Wybierz');
      return false;
    } else {
      setLocationError('');
    }

    return true;
  };

  const clearForm = () => {
    setVehiclePlates('');
    setVehiclePlatesError('');
    setDescription('');
    setVehicleType('');
    setVehicleTypeError('');
    setLocation('');
    setLocationError('');
    setAllDay(true);
    setIsTimePeriod(false);
    setDays(new Array(allDays.length).fill(false));
    setDateFrom(null);
    setDateFromError('');
    setDateTo(null);
    setDateToError('');
    setTimeFrom(null);
    setTimeFromError('');
    setTimeTo(null);
    setTimeToError('');
    // setDateFrom(new Date(selectedEntry.fromDate));
    // setDateTo(new Date(selectedEntry.toDate));
    // setTimeFrom(new Date(selectedEntry.fromHour));
    // setTimeTo(new Date(selectedEntry.toHour));

    // setDays(selectedEntry.daysOfWeek);
    // console.log(selectedEntry.fromDate);
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }
    var formatedDays = new Array<string>();
    days.forEach((v, i) => {
      if (v) {
        formatedDays.push(allDaysEn[i]);
      }
    });
    const entry = {
      uuid: selectedEntry?.uuid,
      locationUuid: location,
      vehiclePlates: vehiclePlates,
      fromDate: isDateRange ? format(dateFrom ?? new Date(), 'yyyy-MM-dd') : null,
      toDate: isDateRange ? format(dateTo ?? new Date(), 'yyyy-MM-dd') : null,
      toHour: allDay ? null : format(timeTo ?? new Date(), 'HH:mm'),
      fromHour: allDay ? null : format(timeFrom ?? new Date(), 'HH:mm'),
      vehicleTypeUuid: vehicleType,
      daysOfWeek: formatedDays,
      description: description,
    } as WhiteListRow;

    await dispatch(saveWhiteList(entry));

    clearForm();
    onClose(true);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setVehicleType(event.target.value as string);
  };
  const handleDateFromChange = (newValue: Date | null) => {
    setDateFrom(newValue);
  };

  const handleTimePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'false') {
      setDateFromError('');
      setDateToError('');
    }
    setIsTimePeriod(event.target.value === 'true');
  };

  const handleAllDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'true') {
      setTimeFromError('');
      setTimeToError('');
    }
    setAllDay(event.target.value === 'true');
  };

  const handleDaysCheck = (dayIndex: number) => {
    const updatedCheckedState = days.map((item, index) => (index === dayIndex ? !item : item));

    setDays(updatedCheckedState);
  };

  const handleAllDaysCheck = () => {
    if (allSelected) {
      setDays(new Array(allDays.length).fill(false));
    } else {
      setDays(new Array(allDays.length).fill(true));
    }
    setAllSelected(!allSelected);
  };

  const handleVPchange = (vp) => {
    setVehiclePlates(vp);
  };

  const handleDescriptionChange = (description) => {
    setDescription(description);
  };

  const setSelectedDays = (daysParam: string[] | undefined) => {
    if (daysParam === undefined || daysParam === null) {
      return;
    }

    const daysTmp = [...days];
    allDaysEn.forEach((x, i) => {
      if (daysParam.find((d) => d === x) !== undefined) {
        daysTmp[i] = true;
      }
    });
    setDays(daysTmp);
  };

  const Label = styled(Typography)(() => ({
    fontWeight: 600,
  }));

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{isEditMode ? 'Edytuj wpis' : 'Dodaj nowy pojazd'}</DialogTitle>
      <DialogContent>
        <FormGroup>
          <Stack direction={'column'} spacing={1}>
            <Stack direction="column" spacing={1}>
              <Label>Wpisz numer tablicy rejestracyjnej</Label>
              <TextField variant="outlined" value={vehiclePlates} onChange={(e) => handleVPchange(e.target.value)} error={saveClicked && vehiclePlatesError !== ''} helperText={vehiclePlatesError} />
            </Stack>
            <Stack direction="column" spacing={1}>
              <Label>Wybierz rodzaj pojazdu</Label>
              <FormControl error={saveClicked && vehicleTypeError !== ''}>
                <Select value={vehicleType} displayEmpty inputProps={{ 'aria-label': 'Without label' }} onChange={handleChange}>
                  {vehicleTypes.map((vt) => (
                    <MenuItem key={vt.uuid} value={vt.uuid}>
                      {vt.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{vehicleTypeError}</FormHelperText>
              </FormControl>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Label>Opis</Label>
              <TextField variant="outlined" value={description} onChange={(e) => handleDescriptionChange(e.target.value)} />
            </Stack>
            <Stack direction="column" justifyContent={'left'}>
              <Stack direction="row" alignItems="center" spacing={1} justifyContent={'left'}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
                  <FormControlLabel control={<Radio onChange={handleTimePeriodChange} checked={isDateRange} value={true} name="timePeriod" />} label="Terminowo" />
                  <Typography>od:</Typography>
                  <FormControl error={saveClicked && dateFromError !== ''}>
                    <DesktopDatePicker disabled={!isDateRange} label="" value={dateFrom} onChange={handleDateFromChange} />
                    <FormHelperText>{dateFromError}</FormHelperText>
                  </FormControl>

                  <Typography>do:</Typography>
                  <FormControl error={saveClicked && dateToError !== ''}>
                    <DesktopDatePicker disabled={!isDateRange} label="" value={dateTo} onChange={(val) => setDateTo(val)} />
                    <FormHelperText>{dateToError}</FormHelperText>
                  </FormControl>
                </LocalizationProvider>
              </Stack>
              <FormControlLabel
                className={'radio-label'}
                control={<Radio onChange={handleTimePeriodChange} checked={!isDateRange} value={false} sx={{ ml: 0 }} name="timePeriod" />}
                label="Bezterminowo"
              />
            </Stack>
            <Grid container>
              <Grid item xs={12}>
                <Label>Wybierz dni tygodnia</Label>
              </Grid>
              <Grid item xs={3}>
                <Button
                  sx={{
                    backgroundColor: '#6868AC',
                    color: 'white',
                    px: 1,
                    py: 0,
                    '&:hover': {
                      backgroundColor: '#5d5d9a',
                    },
                  }}
                  onClick={handleAllDaysCheck}
                >
                  {allSelected ? 'Odznacz wszystko' : 'Zaznacz wszystko'}
                </Button>
              </Grid>
              <Grid item xs={9}>
                <FormControl error={saveClicked && daysError !== ''}>
                  <Grid container>
                    {allDays.map((x, index) => (
                      <Grid item xs={4} key={index}>
                        <FormControlLabel control={<Checkbox checked={days[index]} onChange={() => handleDaysCheck(index)} />} label={x} />
                      </Grid>
                    ))}
                  </Grid>

                  <FormHelperText>{daysError}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
          <Label>Wybierz godziny</Label>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FormControlLabel control={<Radio onChange={handleAllDayChange} checked={allDay} value={true} name="allDay" />} label="Całodzienny" />
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
              <FormControlLabel control={<Radio onChange={handleAllDayChange} checked={!allDay} value={false} name="allDay" />} label="" />
              <Typography>od:</Typography>
              <FormControl error={saveClicked && timeFromError !== ''}>
                <TimePicker label="" value={timeFrom} onChange={(t) => setTimeFrom(t)} ampm={false} minutesStep={10} />
                <FormHelperText>{timeFromError}</FormHelperText>
              </FormControl>
              <Typography>do:</Typography>
              <FormControl error={saveClicked && timeToError !== ''}>
                <TimePicker label="" value={timeTo} onChange={(t) => setTimeTo(t)} ampm={false} />
                <FormHelperText>{timeToError}</FormHelperText>
              </FormControl>
            </LocalizationProvider>
          </Stack>
          <Stack direction="column">
            <Label>Wybierz miejsce</Label>
            <FormControl error={saveClicked && locationError !== ''}>
              <Select value={location} inputProps={{ 'aria-label': 'Without label' }} onChange={(e) => setLocation(e.target.value)}>
                {locations.map((l) => (
                  <MenuItem key={l.uuid} value={l.uuid}>
                    {l.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{locationError}</FormHelperText>
            </FormControl>
          </Stack>
          <Divider flexItem sx={{ height: '2px', margin: '5px' }} />
          <Label>Autor wpisu</Label>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>{creator? creator : "N/A"}, </Typography><Typography>{creationDate ? format(new Date(creationDate), 'dd.MM.yyyy, HH:mm:ss') : "N/A"}</Typography>
          </Stack>
          <Label>Edytor wpisu</Label>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>{lastModifier ? lastModifier : "N/A"}, </Typography><Typography>{lastModificationDate ? format(new Date(lastModificationDate), 'dd.MM.yyyy, HH:mm:ss') : "N/A"}</Typography>
          </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Anuluj</Button>
        <Button
          onClick={handleSave}
          sx={{
            display: isEditMode ? 'none' : 'block',
            px: 3,
            backgroundColor: '#6868AC',
            '&:hover ': { backgroundColor: '#5d5d9a' },
            color: 'white',
          }}
        >
          Dodaj pojazd
        </Button>
        <Button
          onClick={handleSave}
          sx={{
            display: isEditMode ? 'block' : 'none',
            px: 3,
            backgroundColor: '#6868AC',
            '&:hover ': { backgroundColor: '#5d5d9a' },
            color: 'white',
          }}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhiteListDialog;
